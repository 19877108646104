import { cn } from "~/lib/utils";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | "primary"
    | "secondary"
    | "outline"
    | "default"
    | "ghost"
    | "social"
    | "email"
    | "destructive";
  size?: "xs" | "sm" | "md" | "lg" | "icon";
  width?: "auto" | "full" | "xs" | "sm" | "md" | "lg";
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
}

export function Button({
  variant = "default",
  size = "md",
  width = "auto",
  icon,
  iconPosition = "left",
  children,
  className,
  isLoading = false,
  disabled,
  ...props
}: ButtonProps) {
  const variants = {
    default: "bg-white text-gray-900 border border-gray-200 hover:bg-gray-50",
    primary: "bg-primary text-white hover:bg-blue-700",
    secondary: "bg-gray-200 text-gray-800 hover:bg-gray-300",
    outline:
      "border border-natural-gray-200 text-natural-gray-800 hover:bg-gray-50",
    social:
      "bg-white text-primary-deep gap-2 rounded-md shadow-[0_4px_0_#ADAEB0] active:translate-y-1",
    email:
      "text-[#8D2C0D] gap-2 rounded-md shadow-[0_4px_0_#ADAEB0] bg-[#FFE589] active:translate-y-1",
    destructive: "text-white bg-red-500 hover:bg-red-600 active:translate-y-1",
  };

  const sizes = {
    xs: "px-4 py-0.5 text-xs",
    sm: "px-6 py-1 text-sm",
    md: "px-6 py-1.5",
    lg: "px-10 py-3 text-lg",
  };

  const widths = {
    auto: "w-auto",
    full: "w-full",
    xs: "w-20",
    sm: "w-32",
    md: "w-48",
    lg: "w-64",
  };

  return (
    <button
      className={cn(
        "rounded-full inline-flex items-center justify-center transition-colors",
        "font-bold",
        "focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
        "disabled:opacity-50 disabled:cursor-not-allowed",
        isLoading && "relative text-transparent hover:text-transparent",
        variants[variant],
        sizes[size],
        widths[width],
        className
      )}
      disabled={disabled || isLoading}
      {...props}
    >
      {iconPosition === "left" && icon}
      {children}
      {iconPosition === "right" && icon}
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <svg
            className="animate-spin h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
    </button>
  );
}
